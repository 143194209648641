import { useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { BellMeMenu } from '@/utils/incident'
import { LineContext } from '@/contexts/line/context'
import getConfig from 'next/config'
const { publicRuntimeConfig: config } = getConfig()

export default function Home() {
	const router = useRouter()
	const { defaultLiffObject } = useContext(LineContext)

	useEffect(() => {
		if (router.query?.['liff.state']) {
			router.push(router.query?.['liff.state'] as string)
		} else {
			if (defaultLiffObject?.liff?.isInClient() && defaultLiffObject?.lineOAId !== config.line.defaultLineOAId) {
				router.push(BellMeMenu.Protal)
			} else {
				router.push(BellMeMenu.Overview)
			}
		}
	}, [])

	return <div></div>
}
